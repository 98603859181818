import $ from 'jquery'

$(document).on(
  'focus',
  '.field-wrapper input, .field-wrapper select',
  function () {
    return $(this).parents('.field-wrapper').addClass('field-wrapper-focus')
  }
)

$(document).on(
  'blur',
  '.field-wrapper input, .field-wrapper select',
  function () {
    return $(this).parents('.field-wrapper').removeClass('field-wrapper-focus')
  }
)

$(document).on(
  'change keydown keyup keypress',
  '.field-wrapper:not(.field-wrapper-always-value) input, .field-wrapper:not(.field-wrapper-always-value) textarea',
  function () {
    const $this = $(this)
    return $this
      .parents('.field-wrapper')
      .toggleClass('field-wrapper-with-value', !!$.trim($this.val()))
  }
)
